<template>
  <main class="default-online">
    <div class="none"></div>
    <div class="online-call">
      <div class="call-tel">
        <div class="cell"><hr></div>
        <span class="txt fixed">客服电话</span>
        <div class="cell"><hr></div>
      </div>
      <div class="tel-number">{{orgInfo.telephone}}</div>
      <div class="call-suggest">
        <div class="suggest-title">
          <div class="cell"><hr></div>
          <span class="txt fixed">投诉建议</span>
          <div class="cell"><hr></div>
        </div>
        <div class="suggest-form">
          <el-form :model="ruleForm" ref="ruleForm">
            <div class="form-inline">
              <el-form-item label="" prop="username" :rules="rules.fillOut">
                <el-input name="username" placeholder="请输入您的姓名" v-model="ruleForm.username"></el-input>
              </el-form-item>
              <el-form-item label="" prop="mobile" :rules="rules.phone">
                <el-input name="mobile" placeholder="请输入您的联系方式" v-model="ruleForm.mobile"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="" prop="content" :rules="rules.fillOut">
              <el-input name="content" placeholder="请输入您的反馈内容" type="textarea" rows="9" v-model="ruleForm.content" style="height: 202px"></el-input>
            </el-form-item>
            <div class="form-btn">
              <button type="button" @click="submitForm('ruleForm')">提交信息</button>
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <xgj-modal v-model="xgjShowModal" width="452px" @close="close">
      <div class="popup-online">
        <div class="popup-online-close"><i class="sea-iconfont" @click="xgjShowModal=false">&#xe809;</i></div>
        <div class="popup-online-tips">
          <duv class="tips-lef" v-if="orgInfo.wx_image">
            <img class="code" :src="orgInfo.wx_image.url"/>
          </duv>
          <duv class="tips-rig">
            <div class="txt">在<span>个人中心</span>查看反馈进度</div>
            <div class="txt">或扫描关注沈阳海葬公众号进行查看</div>
          </duv>
        </div>
      </div>
    </xgj-modal>
  </main>
</template>

<script>
import rules from "@/utils/rules";
import { mapState } from 'vuex'
import Logindialog from '@/plugins/login/main'
export default {
  name: 'online-call',
  data() {
    return {
      rules,
      xgjShowModal: false,
      ruleForm: {
        username: '',
        mobile: '',
        content: ''
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      token: state => state.user.token,
      orgInfo: state => state.user.orgInfo
    })
  },
  created() {
    console.log(22)
    if (this.token && this.token !== 'undefined') {
      if (this.userInfo) {
        this.ruleForm.username = this.userInfo.name || null;
        this.ruleForm.mobile = this.userInfo.mobile || null
      }
    } else {
      Logindialog({visible: true})
    }
  },
  methods: {
    close() {
      this.xgjShowModal = false;
    },
    submitForm(formName) {
      const that = this;
      if (this.token && this.token !== 'undefined') {
        that.$refs[formName].validate((valid) => {
          if (valid) {
            let formDate = {
              user: {id: that.userInfo.id},
              partner: {id: that.$store.state.user.partnerId},
            };
            let newParams = Object.assign(formDate, that.ruleForm);
            that.$api.website.postNewInteraction(newParams)
              .then( dates => {
                that.$store.commit('showMessage',{
                  message: '提交成功',
                  type: 'success'
                });
                that.$set(that.ruleForm, 'content', '');
                that.xgjShowModal = true
              }).catch( err => {
              that.$store.commit('showMessage',{
                message: err
              })
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        Logindialog({visible: true})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./call-center.scss";
</style>
